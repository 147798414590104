@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.sa-bg-image {
  background-image: linear-gradient(
      rgb(255, 255, 255),
      rgba(255, 255, 255, 0.822)
    ),
    url(~assets/sa-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.auth {
  max-width: 400px;
  background-color: transparent;

  .sa-copyrightfooter {
    background-color: transparent;
    color: $primary;
    text-align: center;
  }

  .sa-input {
    box-shadow: 0 3px 6px gray;
    border-radius: 10px;
    background-color: $primary;
    padding: 5px;
    font-size: 1em;
    font-family: SofiaProMedium;
    font-weight: 100;
  }

  .sa-input-change-password {
    box-shadow: 0 3px 6px gray;
    border-radius: 10px;
    background-color: $primary;
    padding: 5px;
    font-size: 1em;
    font-family: SofiaProMedium;
    font-weight: 100;
  }

  .sa-login-btn {
    text-transform: capitalize;
    background: $positive;
    color: white;
    padding: 10;
  }
}

@import 'node_modules/quasar/src/css/index';

.sa-page {
  @extend .q-pa-md;
}
.sa-box-0 {
  background-image: linear-gradient(90deg, #183b4e 10%, #245874 100%);
}
.sa-box-1 {
  background-image: linear-gradient(135deg, #e6c42f 10%, #eb5c34 100%);
}
.sa-box-2 {
  background-image: linear-gradient(135deg, #eb5c34 10%, #c63c8e 100%);
}
.sa-box-3 {
  background-image: linear-gradient(135deg, #c63c8e 10%, #8c5da3 100%);
}
.sa-box-4 {
  background-image: linear-gradient(135deg, #8c5da3 10%, #4cc0df 100%);
}

.sa-box-5 {
  background-image: linear-gradient(135deg, #265d7a 10%, #183b4e 100%);
}

.sa-box-6 {
  background-image: linear-gradient(135deg, #e0e0e0 10%, #bdbdbd 100%);
}

.sa-box-7 {
  background-image: linear-gradient(180deg, #fff 10%, #fff 100%);
}

.sa-Title-Page {
  text-align: left;
  font-size: 1.2em;
  color: $sa-Donkerblauw;
  font-family: SofiaProMedium;
  font-weight: 200;
}
.sa-Sub-Title-Page {
  text-align: left;
  font-size: 1.1em;
  color: $grey-8;
  font-family: SofiaProMedium;
  font-weight: 200;
}

.sa-Sub-Title-Item {
  text-align: left;
  font-size: 0.8em;
  color: $grey-6;
  font-family: SofiaProMedium;
  font-weight: 300;
}

.sa-Title {
  text-align: left;
  font-size: 0.75em;
  color: $sa-Donkerblauw;
}

.sa-Value {
  font-size: 0.9em;
  color: $sa-Blauw;
}

.sa-KPIValue {
  font-size: 26px;
  //color: $sa-Donkerblauw;
  color: $sa-Blauw;
  font-family: SofiaProMedium;
  font-weight: 300;
}

.sa-KPIName {
  font-size: 20px;
  color: $sa-Donkerblauw;
  //color: $grey-8;
  font-weight: 400;
}

.sa-KPIName1 {
  font-size: 0.9em;
  color: $sa-White;
  font-weight: 800;
}

.sa-bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.sa-main-drawer {
  @extend .text-teal-1;
  background-color: $sa-Donkerblauw;

  .q-separator {
    background-color: $sa-Green;
    height: 1px;
  }

  .main-drawer-item {
    &.q-router-link--exact-active {
      background-color: $sa-Blauw;
    }
    .q-avatar {
      background-color: $sa-Green;
      color: white;
    }
  }

  .sub-drawer-item {
    background-color: transparent;
    &.q-router-link--exact-active {
      background-color: $sa-Blauw;
    }
    .q-avatar {
      background-color: transparent;
      color: $sa-Blauw;
    }
  }
}

.sa-default-table {
  @extend .sa-table;
}

.sa-table {
  @extend .shadow-0;

  .q-table__top,
  .q-.q-table__bottom,
  thead tr:first-child {
    // Header overall
    background: $grey-1;
  }

  th {
    /*  Colmn titles */
    /* background-color: grey; */
    color: $grey-8;
    background: white;
  }

  thead tr th {
    /* header */
    position: sticky;
    z-index: 1;
    padding-top: xs;
    padding-bottom: xs;
    font-size: 14px;
    font-weight: 100;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: SofiaProMedium;
  }

  .q-table tbody td {
    /* lines with data */
    height: 0px;
    padding-top: xs;
    padding-bottom: xs;
    font-size: 14px;
    font-weight: 0;
    margin-top: 0px;
    margin-bottom: opx;
    font-family: SofiaProRegular;
  }

  thead tr:first-child th {
    top: 0;
  }

  &.q-table--loading thead tr:last-child th {
    /* height of all previous header rows */
    top: 0px;
  }
  tbody td:after {
    background: $sa-Selected;
  }
}

.sa-q-table-sub {
  .q-table {
    thead th {
      padding-top: 1em;
      padding-bottom: 1em;
      font-size: 0.75em;
      font-weight: 400;
      font-family: SofiaProMedium;
      color: $sa-Donkerblauw;
      background: $grey-3;
    }

    tbody td:after {
      background: $sa-Selected;
    }

    tbody td {
      padding-top: 1em;
      padding-bottom: 1em;
      font-size: 0.75em;
      font-weight: 400;
      font-family: SofiaProMedium;
    }
  }
}

.sa-q-btn-table-one {
  background-color: white;
  color: $sa-Button;
}

.sa-q-btn-table-edit {
  text-align: center;
  font-size: 10px;
  color: $secondary;
}

.sa-q-btn-table-delete {
  text-align: center;
  font-size: 10px;
  color: $negative;
}

.sa-q-btn-close {
  @extend .sa-box-2, .text-white, .q-px-md, .q-mb-md;
}

// Standard objects -------------------------------------------------------------

.q-fab {
  background-color: $bg;
}
.q-tab-panel {
  background-color: $bg;
}

.q-expansion-item {
  .q-icon {
    font-size: 16px;
  }

  .q-item__label {
    font-size: 16px;
  }
}
.q-circular-progress {
  @extend .q-my-md;
  color: $sa-Blauw;
  border-radius: 3em;
  font-size: 4em;
  .q-icon {
    color: $sa-Blauw;
  }
}

.q-btn:disabled {
  color: $grey-8;
  background: $grey-4;
  box-shadow: 0px 0px;
}

.small-table {
  thead th {
    font-size: 0.7em;
    font-weight: 400;
    font-family: SofiaProMedium;
    color: white;
    background: $sa-Paars;
  }

  thead tr {
    background-color: #dff4f8;

    &:last-child {
      top: 48px;
    }
  }

  .scroll-indicator {
    opacity: 0;
    height: 0;
  }

  tbody td:after {
    background: $sa-Selected;
  }

  tbody td {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-size: 0.7em;
    font-weight: 400;
    font-family: SofiaProMedium;
  }
}

.q-badge {
  background-color: $sa-Koraal;
  font-weight: 600;
  font-family: SofiaProMedium;
}

.q-select {
  &_pointer .q-field__control .q-field__native {
    cursor: pointer;
  }
  &_datepicker {
    min-width: 3em;
  }

  .q-field__control {
    background-color: $grey-4;
    padding-left: 1em;
    padding-right: 1em;
  }
  .q-field__label {
    color: white;
  }
  &.q-field--readonly .q-field__control:before {
    border: 2px solid $sa-Highlight-1;
  }
}

.q-tabs {
  :active {
    color: $sa-Blauw;
    .q-icon: blue;
  }
}

// End standard objects -------------------------------------------------------------

@font-face {
  font-family: SofiaProMedium;
  src: url('~assets/fonts/SofiaProMedium.ttf');
}

@font-face {
  font-family: SofiaProRegular;
  src: url('~assets/fonts/SofiaProRegular.ttf');
}

@font-face {
  font-family: 'SofiaPro';
  font-style: normal;
  font-weight: 300;
  src: url('~assets/fonts/SofiaProLightAz.otf') format('opentype');
}

@font-face {
  font-family: 'SofiaPro';
  font-style: normal;
  font-weight: 400;
  src: url('~assets/fonts/SofiaProRegularAz.otf') format('opentype');
}

@font-face {
  font-family: 'SofiaPro';
  font-style: normal;
  font-weight: 500;
  src: url('~assets/fonts/SofiaProMediumAz.otf') format('opentype');
}

@font-face {
  font-family: 'SofiaPro';
  font-style: normal;
  font-weight: 600;
  src: url('~assets/fonts/SofiaProSemiBoldAz.otf') format('opentype');
}

@font-face {
  font-family: 'SofiaPro';
  font-style: normal;
  font-weight: 700;
  src: url('~assets/fonts/SofiaProBoldAz.otf') format('opentype');
}

body {
  background: $bg;
  font-family: 'SofiaPro';
  color: $dark;
  font-size: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  line-height: 1;
}

.app-dialog {
  min-width: 10em;
}

.text-label {
  color: $additional;
}

#q-app {
  a {
    color: $additional;
    text-decoration: none;
  }

  .app-link {
    color: $dark;
    &:hover {
      transition: 0.3s;
      color: $additional;
    }
  }

  .q-checkbox__label {
    color: $additional;
  }

  .text-500 {
    font-weight: 500;
  }

  .text-600 {
    font-weight: 600;
  }

  .text-300 {
    font-weight: 300;
  }

  .text-md {
    font-size: $space-2;
    font-weight: 500;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-horizontal {
    overflow-x: auto;
    flex-wrap: nowrap;
    padding-bottom: $space-1;
  }

  .title {
    font-size: 2em;
    font-weight: 500;
  }

  .subtitle {
    font-size: 1em;
    font-weight: 300;
    text-transform: uppercase;
  }

  .q-item .app-input {
    max-width: 5em;
  }

  .app-page {
    padding: $space-3;
  }

  .drawer-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    .logout {
      margin: 2em auto;
      border-radius: $app-border-radius;
    }
    .copryrights {
      font-size: 0.75em;
      margin: 1em 0;
    }
  }

  .auth-btn {
    text-transform: capitalize;
    width: 100%;
  }

  .initials .q-avatar__content {
    padding-top: 0.2em;
  }

  .btn-more {
    line-height: 1;
    font-weight: 400;
    padding: 0;
    &::v-deep {
      .q-btn__wrapper {
        padding: 0;
      }
    }
  }
  .pagination {
    background-color: #fff;
    border-radius: $app-border-radius;
    padding: $space-1;
    box-shadow: 0px -1px 15px rgba(0, 0, 0, 0.04),
      0px 4px 15px rgba(0, 0, 0, 0.08);
  }
}

.processed {
  background: $sa-Selected !important;
}

.not-available {
  font-size: 12px;
  color: $additional;
}

.assets-table {
  padding: 0;
  color: $dark;
  min-height: 350px;
  .table {
    position: relative;
  }
  .scroll-indicator {
    opacity: 0;
    height: 0;
  }
  tbody tr:last-of-type td {
    border-bottom: 1px solid $grey;
  }
  thead tr {
    background-color: #dff4f8;
    th {
      font-weight: 600;
      text-align: center;
      padding: 0 2px;
      position: sticky;
      top: 0;
      z-index: 100;
    }

    &:first-child th:first-child {
      text-align: left;
      position: sticky;
      left: 0;
      top: 0;
      padding: $space-1;
      padding-left: 32px;
      z-index: 200;
      background-color: #dff4f8;
    }

    &:first-child th:last-child {
      min-width: 180px;
    }

    &:first-child th:first-child {
      border-top-left-radius: $app-border-radius;
    }
    &:first-child th:last-child {
      border-top-right-radius: $app-border-radius;
    }
    &:last-child th:first-child {
      border-bottom-left-radius: $app-border-radius;
    }
    &:last-child th:last-child {
      border-bottom-right-radius: $app-border-radius;
    }
  }
  tr {
    border-bottom: 1px solid $additional;
  }
  .asset-name-td {
    width: 10%;
    background-color: #fff;
  }
  .asset-name-wrapper {
    position: relative;
    height: 100%;
    vertical-align: middle;
  }
  .asset-name {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    white-space: normal;
    margin-left: 5px;
  }
  td {
    text-align: center;
    padding: $space-1 4px;
  }
  td:first-of-type {
    text-align: left;
    position: sticky;
    z-index: 100;
    left: 0;
  }

  .asset-number {
    margin-right: $space-1;
    border-radius: 50%;
    background-color: $secondary;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 20px;
    height: 20px;
    background: #fff;
  }
  .bar {
    align-items: center;
    .legend__stack {
      display: none;
    }
    .item__stack {
      border-radius: $app-border-radius;
      height: 25px;
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
    .label__stack {
      top: -2px;
    }
    .percent__stack {
      z-index: 10;
    }
  }
}

.state-legend {
  display: flex;
  justify-content: center;
  .legend-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 15px;
  }
  .color {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-bottom: 2px;
    margin-right: 2px;
    border: 1px solid $additional;
  }
  .label {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 10px;
  }
}

.loading-main {
  @media (min-width: $breakpoint-sm-max) {
    left: 300px;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 1s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  will-change: opacity;
}

.tooltip-item {
  position: relative;
  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
  }
}

.tooltip {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 4px;
  background-color: $grey;
  border-radius: 4px;
  &:after {
    position: absolute;
    content: '';
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $grey;
  }
}

.sa-markup-table {
  background: white;

  thead {
    background: $sa-Paars;
    th {
      width: 150px;
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 16px;
      font-weight: 400;
      margin-top: 5px;
      margin-bottom: 10px;
      font-family: SofiaProMedium;
      color: white;
    }
  }
  tbody {
    .td-first {
      font-size: 18px;
    }
    td {
      padding-bottom: 15px;
      padding-top: 15px;
      font-size: 15px;
    }
  }

  .q-badge {
    background-color: $sa-Koraal;
  }
}
